







































































import { defineComponent, toRefs } from '@vue/composition-api'
import useNews from '../hooks/use-news'

export default defineComponent({
  name: 'MobileNews',
  components: {},
  setup() {
    const { state, handleClick, handleSelect, parseTime, handleResolve } =
      useNews()
    return {
      ...toRefs(state),
      handleClick,
      handleSelect,
      parseTime,
      handleResolve
    }
  }
})
